import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { RoleGuard } from "../App";

const ArchivedDataTransactions = () => {
  const propertyId = localStorage.getItem("selectedPropertyId");
  const [transactions, setTransactions] = useState([]);
  const [year, setYear] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchTransactions = () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/alltransactionsviewbyyear/${propertyId}`,
        { year },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setTransactions(response.data.response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching archived transactions:", error);
        toast.error(
          error.response?.data?.response ||
            "Error fetching archived transactions"
        );
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchTransactions();
  }, [propertyId, year]);

  return (
    <RoleGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Archived Transactions</h1>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Select Year:
            </label>
            <input
              type="number"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          {isLoading ? (
            <div className="flex justify-center">
              <Oval
                height={40}
                width={40}
                color="blue"
                ariaLabel="loading-indicator"
              />
            </div>
          ) : (
            <table className="mt-4 w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="px-4 py-2 border">Date of Transaction</th>
                  <th className="px-4 py-2 border">Transaction ID</th>
                  <th className="px-4 py-2 border">Channel</th>
                  <th className="px-4 py-2 border">Transaction Amount</th>
                  <th className="px-4 py-2 border">Running Balance</th>
                </tr>
              </thead>
              <tbody>
                {transactions.length > 0 ? (
                  transactions.map((transaction) => (
                    <tr key={transaction.transaction_id}>
                      <td className="border px-4 py-2">
                        {new Date(transaction.created_at).toLocaleDateString()}
                      </td>
                      <td className="border px-4 py-2">
                        {transaction.transaction_id}
                      </td>
                      <td className="border px-4 py-2">
                        {transaction.reference}
                      </td>
                      <td className="border px-4 py-2">{transaction.amount}</td>
                      <td className="border px-4 py-2">
                        {transaction.running_balance}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" className="border px-4 py-2 text-center">
                      No archived transactions found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </AuthGuard>
    </RoleGuard>
  );
};

export default ArchivedDataTransactions;
