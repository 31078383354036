import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { RoleGuard } from "../App";

const CaretakersPage = () => {
  const [caretakers, setCaretakers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const propertyId = localStorage.getItem("selectedPropertyId");
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/users/${propertyId}/caretaker`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setCaretakers(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching caretakers:", error);
      });
  }, []);

  const handleAddButtonClick = () => {
    navigate("/landlord/registercaretaker");
  };

  const handleDeleteButtonClick = (caretaker) => {
    // Code to set the specific caretaker uuid and navigate to delete page
    localStorage.setItem("selectedCaretakerId", caretaker.uuid);
    navigate("/landlord/deletecaretaker");
  };

  return (
    <RoleGuard>
      <AuthGuard>
        <div className="container mx-auto">
          <h1 className="text-3xl font-semibold my-8">Caretakers</h1>
          <button
            onClick={handleAddButtonClick}
            className="bg-blue-500 text-white py-2 px-4 rounded mb-4"
          >
            Add Caretaker
          </button>
          <div>
            {caretakers.map((caretaker) => (
              <div key={caretaker.id} className="border rounded p-4 my-2">
                <h2 className="text-xl font-semibold">{caretaker.name}</h2>
                <p>Email: {caretaker.email}</p>
                <p>Phone Number: {caretaker.phone}</p>
                <p>Role: {caretaker.role}</p>
                <p>Verify Status: {caretaker.status}</p>
                <button
                  onClick={() => handleDeleteButtonClick(caretaker)}
                  className="bg-red-500 text-white py-1 px-2 rounded mt-2"
                >
                  Delete
                </button>
              </div>
            ))}
          </div>
        </div>
      </AuthGuard>
    </RoleGuard>
  );
};

export default CaretakersPage;
