import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { RoleGuard } from "../App";

const ArchivedData = () => {
  const [year, setYear] = useState("");
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");
  const PropertyId = localStorage.getItem("selectedPropertyId");

  const fetchRecords = () => {
    if (!year.match(/^\d{4}$/)) {
      toast.error("Please enter a valid 4-digit year");
      return;
    }

    setLoading(true);

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/viewrecordsrentlandlordyearselect/${PropertyId}`,
        { year }, // Sending the year as JSON
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setRecords(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching records:", error);
        toast.error("Error fetching records");
      })
      .finally(() => setLoading(false));
  };

  return (
    <RoleGuard>
      <AuthGuard>
    <div className="flex flex-col items-center min-h-screen bg-gray-100 p-6">
      <h3 className="text-xl font-semibold mb-4">Enter Year:</h3>

      <div className="flex space-x-2 mb-4">
        <input
          type="number"
          maxLength={4}
          placeholder="YYYY"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
        <button
          onClick={fetchRecords}
          disabled={loading}
          className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 disabled:opacity-50"
        >
          {loading ? "Loading..." : "View Records"}
        </button>
      </div>

      {records.length > 0 ? (
        <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg overflow-hidden">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-blue-500 text-white">
                <th className="py-2 px-4">Record ID</th>
                <th className="py-2 px-4">House Number</th>
                <th className="py-2 px-4">Water Bill</th>
                <th className="py-2 px-4">Rent Bill</th>
                <th className="py-2 px-4">Total Amount Paid</th>
                <th className="py-2 px-4">Date</th>
              </tr>
            </thead>
            <tbody>
              {records.map((item, index) => (
                <tr
                  key={item.record_id}
                  className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                >
                  <td className="py-2 px-4 text-center">{item.record_id}</td>
                  <td className="py-2 px-4 text-center">{item.house_number}</td>
                  <td className="py-2 px-4 text-center">{item.water_bill}</td>
                  <td className="py-2 px-4 text-center">{item.rent_bill}</td>
                  <td className="py-2 px-4 text-center">{item.total_amount_paid}</td>
                  <td className="py-2 px-4 text-center">{item.created_at}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-gray-600 mt-4">No records found</p>
      )}
    </div>
    </AuthGuard>
    </RoleGuard>
  );
};

export default ArchivedData;
