import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard, RoleGuard } from "../App";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BankDetailsPage = () => {
  const [bankDetails, setBankDetails] = useState([]);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [newBankDetail, setNewBankDetail] = useState({
    bankaccount: "",
    bankcode: "",
    sendernames: "",
    threshhold: "",
    phonenumber: "",
    settlementtype: "bank", // Default to bank
  });
  const [updateBankDetail, setUpdateBankDetail] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const token = localStorage.getItem("token");
  const propertyId = localStorage.getItem("selectedPropertyId");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          // Fetch bank details
          const bankDetailsResponse = await axios.get(
            "https://backend.inyumba.co.ke/iNyumba/admin/propertysettlementdetailsbank/viewbankdetails",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                propertyid: propertyId, // Pass propertyid as a query parameter
              },
            }
          );
          setBankDetails([bankDetailsResponse.data.response]); // Wrap response in an array
        } catch (error) {
          console.error("Error fetching bank details:", error);
          toast.error(
            error.response?.data?.response ||
              "Failed to fetch bank details. Please try again."
          );
        }

        try {
          // Fetch banks
          const banksResponse = await axios.get(
            "https://backend.inyumba.co.ke/iNyumba/admin/bank/fetch",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setBanks(banksResponse.data.response);
        } catch (error) {
          console.error("Error fetching banks:", error);
          toast.error(
            error.response?.data?.response ||
              "Failed to fetch banks. Please try again."
          );
        }
      }
    };

    fetchData();
  }, [token, propertyId, setBankDetails]);

  // Handle add bank detail
  const handleAddBankDetail = () => {
    if (token) {
      const payload = { ...newBankDetail, propertyid: propertyId };

      axios
        .post(
          "https://backend.inyumba.co.ke/iNyumba/admin/propertysettlementdetailsbank/createsettlementdetailsstore",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setBankDetails((prevDetails) => [
            ...prevDetails,
            response.data.response,
          ]);
          setShowAddModal(false); // Close modal
        })
        .catch((error) => {
          console.error("Error adding bank detail:", error);
          toast.error(error.response.data.response);
        });
    }
  };

  const handleDeleteBankAccount = (propertyId) => {
    if (
      window.confirm("Are you sure you want to delete this settlement account?")
    ) {
      axios
        .delete(
          "https://backend.inyumba.co.ke/iNyumba/admin/propertysettlementdetailsbank/deletebankdetails",
          {
            params: { propertyid: propertyId }, // Pass as query parameter
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          toast.success(
            response.data.response || "Settlement deleted successfully."
          );
          setBankDetails((prev) =>
            prev.filter((detail) => detail.propertyid !== propertyId)
          );
        })
        .catch((error) => {
          console.error("Error deleting settlement account:", error);
          toast.error(
            error.response?.data?.response ||
              "Failed to delete settlement account. Please try again."
          );
        });
    }
  };

  // Handle update bank detail
  const handleUpdateBankDetail = () => {
    if (token && updateBankDetail) {
      const payload = { ...updateBankDetail, propertyid: propertyId };

      axios
        .patch(
          "https://backend.inyumba.co.ke/iNyumba/admin/propertysettlementdetailsbank/updatebankdetails",
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setBankDetails((prevDetails) =>
            prevDetails.map((detail) =>
              detail.id === updateBankDetail.id
                ? { ...detail, ...updateBankDetail }
                : detail
            )
          );
          setShowUpdateModal(false); // Close modal
        })
        .catch((error) => {
          console.error("Error updating bank detail:", error);
          toast.error(error.response.data.response);
        });
    }
  };

  return (
    <RoleGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Settlement Details</h1>

          {bankDetails.length === 0 && (
            <button
              onClick={() => setShowAddModal(true)}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mb-8"
            >
              Add Account
            </button>
          )}

          {/* Bank Details List */}
          <h2 className="text-xl font-semibold mb-4">
            Existing Settlement Details
          </h2>
          <table className="min-w-full bg-white border border-gray-300 rounded-md">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-300 text-left">
                  Account Name
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-left">
                  Bank Code
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-left">
                  Account Number
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-left">
                  Phone Number
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-left">
                  Settlement Type
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-left">
                  Threshold
                </th>
                <th className="py-2 px-4 border-b border-gray-300 text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {bankDetails.map((detail) => (
                <tr key={detail.id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b border-gray-300">
                    {detail.sendername}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {detail.bankcode}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {detail.bankaccount}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {detail.phonenumber}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {detail.settlementtype}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    {detail.threshhold}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-300">
                    <button
                      onClick={() => {
                        setUpdateBankDetail(detail);
                        setShowUpdateModal(true);
                      }}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Update
                    </button>
                    <button
                      onClick={() => handleDeleteBankAccount(detail.propertyid)} // Pass the property ID to delete
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-2"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Add Account Modal */}
          {showAddModal && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-8 rounded-md shadow-md w-full max-w-lg">
                <h2 className="text-xl font-semibold mb-4">Add Bank Account</h2>
                <input
                  type="number"
                  placeholder="Account Number"
                  value={newBankDetail.bankaccount}
                  onChange={(e) =>
                    setNewBankDetail({
                      ...newBankDetail,
                      bankaccount: e.target.value,
                    })
                  }
                  className="border rounded px-4 py-2 mb-2 w-full"
                  required
                />
                <select
                  value={newBankDetail.bankcode}
                  onChange={(e) =>
                    setNewBankDetail({
                      ...newBankDetail,
                      bankcode: e.target.value,
                    })
                  }
                  className="w-full border border-gray-300 rounded-md p-2 mb-2"
                >
                  <option value="">Select Bank</option>
                  {banks.map((bank) => (
                    <option key={bank.bankcode} value={bank.bankcode}>
                      {bank.bankname}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  placeholder="Account Name"
                  value={newBankDetail.sendernames}
                  onChange={(e) =>
                    setNewBankDetail({
                      ...newBankDetail,
                      sendernames: e.target.value,
                    })
                  }
                  className="border rounded px-4 py-2 mb-2 w-full"
                  required
                />
                <input
                  type="number"
                  placeholder="Threshhold you want your money remitted at"
                  value={newBankDetail.threshhold}
                  onChange={(e) =>
                    setNewBankDetail({
                      ...newBankDetail,
                      threshhold: e.target.value,
                    })
                  }
                  className="border rounded px-4 py-2 mb-2 w-full"
                  required
                />
                <input
                  type="text"
                  placeholder="Phone Number. Start with 2547 or 2541"
                  value={newBankDetail.phonenumber}
                  onChange={(e) =>
                    setNewBankDetail({
                      ...newBankDetail,
                      phonenumber: e.target.value,
                    })
                  }
                  className="border rounded px-4 py-2 mb-2 w-full"
                  required
                />

                {/* Settlement Type Select */}
                <select
                  value={newBankDetail.settlementtype}
                  onChange={(e) =>
                    setNewBankDetail({
                      ...newBankDetail,
                      settlementtype: e.target.value,
                    })
                  }
                  className="w-full border border-gray-300 rounded-md p-2 mb-2"
                >
                  <option value="bank">Bank</option>
                  <option value="mpesa">M-Pesa</option>
                </select>

                <button
                  onClick={handleAddBankDetail}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                >
                  Save
                </button>
                <button
                  onClick={() => setShowAddModal(false)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          {/* Update Account Modal */}
          {showUpdateModal && updateBankDetail && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-8 rounded-md shadow-md w-full max-w-lg">
                <h2 className="text-xl font-semibold mb-4">
                  Update Bank Account
                </h2>
                <input
                  type="number"
                  placeholder="Account Number"
                  value={updateBankDetail.bankaccount}
                  onChange={(e) =>
                    setUpdateBankDetail({
                      ...updateBankDetail,
                      bankaccount: e.target.value,
                    })
                  }
                  className="border rounded px-4 py-2 mb-2 w-full"
                  required
                />
                <select
                  value={updateBankDetail.bankcode}
                  onChange={(e) =>
                    setUpdateBankDetail({
                      ...updateBankDetail,
                      bankcode: e.target.value,
                    })
                  }
                  className="w-full border border-gray-300 rounded-md p-2 mb-2"
                >
                  <option value="">Select Bank</option>
                  {banks.map((bank) => (
                    <option key={bank.bankcode} value={bank.bankcode}>
                      {bank.bankname}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  placeholder="Account Names"
                  value={updateBankDetail.sendernames}
                  onChange={(e) =>
                    setUpdateBankDetail({
                      ...updateBankDetail,
                      sendernames: e.target.value,
                    })
                  }
                  className="border rounded px-4 py-2 mb-2 w-full"
                  required
                />
                <input
                  type="number"
                  placeholder="Threshhold you want your money remitted at"
                  value={updateBankDetail.threshhold}
                  onChange={(e) =>
                    setUpdateBankDetail({
                      ...updateBankDetail,
                      threshhold: e.target.value,
                    })
                  }
                  className="border rounded px-4 py-2 mb-2 w-full"
                  required
                />
                <input
                  type="text"
                  placeholder="Phone Number"
                  value={updateBankDetail.phonenumber}
                  onChange={(e) =>
                    setUpdateBankDetail({
                      ...updateBankDetail,
                      phonenumber: e.target.value,
                    })
                  }
                  className="border rounded px-4 py-2 mb-2 w-full"
                  required
                />

                {/* Settlement Type Select */}
                <select
                  value={updateBankDetail.settlementtype}
                  onChange={(e) =>
                    setUpdateBankDetail({
                      ...updateBankDetail,
                      settlementtype: e.target.value,
                    })
                  }
                  className="w-full border border-gray-300 rounded-md p-2 mb-2"
                >
                  <option value="bank">Bank</option>
                  <option value="mpesa">M-Pesa</option>
                </select>

                <button
                  onClick={handleUpdateBankDetail}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                >
                  Update
                </button>
                <button
                  onClick={() => setShowUpdateModal(false)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </AuthGuard>
    </RoleGuard>
  );
};

export default BankDetailsPage;
