import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faMobileAlt,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons"; // Import icons
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { Oval } from "react-loader-spinner"; // Import loader
import { RoleGuard } from "../App";

const WithdrawalsPage = () => {
  const [balance, setBalance] = useState(null);
  const [showBalance, setShowBalance] = useState(true);
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [showMobileMoneyOptions, setShowMobileMoneyOptions] = useState(false);
  const [showCardPaymentOptions, setShowCardPaymentOptions] = useState(false);
  const [bankAccount, setBankAccount] = useState("");
  const [senderNames, setSenderNames] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Add loading state
  const [isOTPOpen, setIsOTPOpen] = useState(false); // Add OTP modal state
  const [isOTPCardOpen, setIsOTPCardOpen] = useState(false); // Add OTP modal state
  const [otp, setOtp] = useState(["", "", "", "", ""]); // OTP input state
  const [ownerId, setOwnerId] = useState("");

  const token = localStorage.getItem("token");
  const propertyId = localStorage.getItem("selectedPropertyId");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);

  useEffect(() => {
    if (token && propertyId) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/balance/owner/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setBalance(
            response.data.response.length > 0
              ? response.data.response[0].balance
              : 0
          );
        })
        .catch((error) => {
          console.error("Error fetching balance:", error);
        });
    }

    axios
      .get("https://backend.inyumba.co.ke/iNyumba/admin/bank/fetch", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setBanks(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching banks:", error);
      });
  }, [token, propertyId]);

  const handleBankSelect = (bankCode) => {
    setBankCode(bankCode);
    setSelectedBank(bankCode); // Update selectedBank state as well, if needed
  };

  const handleWithdrawalSubmit = async (e) => {
    setOwnerId(userData.unique);
    e.preventDefault();

    if (!token || !propertyId || !withdrawalAmount) {
      console.error(
        "Token, property ID, withdrawal amount, and channel are required."
      );
      return;
    }

    setIsLoading(true); // Set loading state to true

    try {
      let response;

      if (selectedMode === "mobilemoney") {
        const requestData = {
          amount: withdrawalAmount,
          phone_number: phoneNumber,
          channel: selectedChannel,
        };

        const jsonData = JSON.stringify(requestData);
        response = await axios.post(
          `https://backend.inyumba.co.ke/iNyumba/landlordsettlementinitiated/${propertyId}/${ownerId}`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.responseCode === 200) {
          if (
            response.data.response ===
            "Withdrawal has been blocked kindly contact admin"
          ) {
            toast.info("Withdrawal has been blocked. Kindly contact admin.");
          } else if (response.data.response === "Check your email for OTP") {
            toast.info("Check your email for OTP");
            setIsOTPOpen(true);
          } else if (
            response.data.response.includes(
              "You have insufficient funds in your account. Transaction cost is"
            )
          ) {
            toast.error(response.data.response);
          } else if (response.data.responseCode === 201) {
            toast.error(response.data.response);
          } else {
            handleResponseErrors(response.data.response);
          }
        }
      } else if (selectedMode === "cards") {
        if (!selectedBank) {
          toast.error("Please select a bank");
          return;
        }

        const requestData = {
          amount: withdrawalAmount,
          bankaccount: bankAccount,
          bankcode: bankCode,
          sendernames: senderNames,
        };

        const jsonData = JSON.stringify(requestData);

        response = await axios.post(
          `https://backend.inyumba.co.ke/iNyumba/landlordsettlementcardsinitiated/${propertyId}/${ownerId}`,
          jsonData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.responseCode === 200) {
          if (
            response.data.response ===
            "Withdrawal has been blocked kindly contact admin"
          ) {
            toast.info("Withdrawal has been blocked. Kindly contact admin.");
          } else if (response.data.response === "Check your email for OTP") {
            toast.info("Check your email for OTP");
            setIsOTPOpen(false);
            setIsOTPCardOpen(true);
          } else if (
            response.data.response ===
            "You have insufficient funds in your account. Transaction cost is 150"
          ) {
            toast.error(
              "You have insufficient funds in your account. Transaction cost is 150"
            );
          } else if (response.data.responseCode === 201) {
            toast.error(response.data.response);
          }
        } else {
          handleResponseErrors(response.data.response);
        }
      }
    } catch (error) {
      toast.error(
        error.message || "Error submitting withdrawal. Please try again."
      );
      console.error("Error submitting withdrawal:", error);
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };

  const handleResponseErrors = (response) => {
    switch (response) {
      case "Hash mismatch error. Please check your data and try again.":
        toast.error("Hash mismatch error. Please verify your data.");
        break;
      case "Transaction reference exists. Please generate a unique reference.":
        toast.error("Reference exists. Please use a unique reference.");
        break;
      case "One or more parameters are missing.":
        toast.error(
          "Some required parameters are missing. Please check and try again."
        );
        break;
      case "Request from unregistered IP address. Please ensure your IP is registered.":
        toast.error(
          "Unregistered IP address. Please verify your network settings."
        );
        break;
      case "Insufficient funds in your iPay bulkpay account.":
        toast.error("Insufficient funds in your iPay bulkpay account.");
        break;
      default:
        toast.error("Bad request. Please check your input and try again.");
    }
  };

  const handleVerifyOTP = async () => {
    setOwnerId(userData.unique);
    const otpCode = otp.join(""); // Combine the OTP array into a single string

    if (!otpCode || otpCode.length !== 5) {
      toast.error("Please enter a valid 5-digit OTP.");
      return;
    }

    const requestData = {
      amount: withdrawalAmount,
      phone_number: phoneNumber,
      channel: selectedChannel,
      otp: otpCode,
    };

    setIsLoading(true); // Set loading state to true

    const jsonData = JSON.stringify(requestData);

    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/landlordsettlementcompletesettle/${propertyId}/${ownerId}`,
        jsonData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.responseCode === 200) {
        if (
          response.data.response ===
          "Withdrawal has been blocked kindly contact admin"
        ) {
          toast.info("Withdrawal has been blocked. Kindly contact admin.");
          setIsLoading(false); // Set loading state to true
        } else if (
          response.data.response ===
          "Your account has reached the OTP limit of 5"
        ) {
          toast.error("Your account has reached the OTP limit of 5.");
          setIsLoading(false); // Set loading state to true
        } else if (response.data.response === "Invalid OTP") {
          setIsLoading(false); // Set loading state to true
          toast.error(response.data.response);
        } else if (response.data.response === "B2C blocked request") {
          setIsLoading(false); // Set loading state to true
          toast.error(response.data.response);
          closeModal();
        } else if (response.data.response === "B2C processing request") {
          setIsLoading(false); // Set loading state to true
          toast.info(response.data.response);
          closeModal();
        } else {
          setIsLoading(false); // Set loading state to true
          toast.success(response.data.response); // Default success message
        }
      }
    } catch (error) {
      setIsLoading(false); // Set loading state to true
      toast.error("Error verifying OTP. Please try again.");
    }
  };

  const handleResendOTP = async () => {
    setOwnerId(userData.unique);
    setIsLoading(true); // Set loading state to true
    try {
      const response = await axios.patch(
        `https://backend.inyumba.co.ke/iNyumba/landlordsettlementotpresend/${propertyId}/${ownerId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.responseCode === 200) {
        toast.success("OTP resent successfully.");
        setIsLoading(false); // Set loading state to true
      } else {
        toast.error(response.data.response || "Error resending OTP.");
        setIsLoading(false); // Set loading state to true
      }
    } catch (error) {
      setIsLoading(false); // Set loading state to true
      toast.error("Error resending OTP. Please try again.");
    }
  };

  const handleVerifyCardOTP = async () => {
    setOwnerId(userData.unique);
    const otpCode = otp.join(""); // Combine the OTP array into a single string

    if (!otpCode || otpCode.length !== 5) {
      toast.error("Please enter a valid 5-digit OTP.");
      return;
    }

    setIsLoading(true); // Set loading state to true

    const requestData = {
      amount: withdrawalAmount,
      bankaccount: bankAccount,
      bankcode: bankCode,
      sendernames: senderNames,
      otp: otpCode,
    };

    const jsonData = JSON.stringify(requestData);

    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/landlordsettlementcardscompletesettle/${propertyId}/${ownerId}`,
        jsonData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.responseCode === 200) {
        if (
          response.data.response ===
          "Withdrawal has been blocked kindly contact admin"
        ) {
          toast.info("Withdrawal has been blocked. Kindly contact admin.");
          setIsLoading(false); // Set loading state to true
        } else if (
          response.data.response ===
          "Your account has reached the OTP limit of 5"
        ) {
          toast.error("Your account has reached the OTP limit of 5.");
          setIsLoading(false); // Set loading state to true
        } else if (response.data.response === "Invalid OTP") {
          toast.error(response.data.response);
          setIsLoading(false); // Set loading state to true
        } else if (response.data.response === "B2C blocked request") {
          toast.error(response.data.response);
          setIsLoading(false); // Set loading state to true
          closeModal();
        } else if (response.data.response === "B2C processing request") {
          setIsLoading(false); // Set loading state to true
          toast.info(response.data.response);
          closeModal();
        } else {
          setIsLoading(false); // Set loading state to true
          toast.info(response.data.response); // Default success message
        }
      }
    } catch (error) {
      setIsLoading(false); // Set loading state to true
      toast.error("Error verifying OTP. Please try again.");
    }
  };

  const handleResendCardOTP = async () => {
    setOwnerId(userData.unique);
    setIsLoading(true); // Set loading state to true
    try {
      const response = await axios.patch(
        `https://backend.inyumba.co.ke/iNyumba/landlordsettlementcardsotpresend/${propertyId}/${ownerId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.responseCode === 200) {
        setIsLoading(false); // Set loading state to true
        toast.success("OTP resent successfully.");
      } else {
        setIsLoading(false); // Set loading state to true
        toast.error(response.data.response || "Error resending OTP.");
      }
    } catch (error) {
      setIsLoading(false); // Set loading state to true
      toast.error("Error resending OTP. Please try again.");
    }
  };

  const closeModal = () => {
    setIsOTPOpen(false);
    setIsOTPCardOpen(false);
    setOtp(["", "", "", "", ""]);
    setWithdrawalAmount("");
    setPhoneNumber("");
    setSelectedChannel(null);
    setShowMobileMoneyOptions(false);
    setShowCardPaymentOptions(false);
    setSelectedMode(null);
    setBankCode("");
    setSenderNames("");
    setSelectedBank("");
    setBankAccount("");
  };

  const toggleShowBalance = () => {
    setShowBalance(!showBalance);
  };

  const handleModeSelection = (mode) => {
    setSelectedMode(mode);

    if (mode === "mobilemoney") {
      setShowMobileMoneyOptions(true);
      setShowCardPaymentOptions(false);
    } else if (mode === "cards") {
      setShowCardPaymentOptions(true);
      setShowMobileMoneyOptions(false);
    } else {
      setShowMobileMoneyOptions(false);
      setShowCardPaymentOptions(false);
    }
  };

  const handleMobileMoneyClick = (channel) => {
    setSelectedChannel(channel);
  };

  const handleOtpChange = (index, value) => {
    if (/^\d*$/.test(value)) {
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
        return newOtp;
      });
    }
  };

  return (
    <RoleGuard>
    <AuthGuard>
      <div className="p-6">
        <h1 className="text-2xl font-bold mb-4">Withdraw Funds</h1>

        <div className="border border-gray-300 rounded-lg p-6 bg-white">
          <div className="mb-6">
            <div className="flex items-center mb-2">
              <span className="mr-2">Available Balance:</span>
              <span className="font-bold text-lg">
                {showBalance ? balance : "******"}
              </span>
              <button
                onClick={toggleShowBalance}
                className="ml-4 focus:outline-none"
              >
                <FontAwesomeIcon
                  icon={showBalance ? faEyeSlash : faEye}
                  className="text-gray-500"
                />
              </button>
            </div>
          </div>

          <div className="mb-4">
            <label className="block mb-2 font-semibold">Select Mode</label>
            <div className="flex space-x-4">
              <button
                className={`flex items-center focus:outline-none ${
                  selectedMode === "mobilemoney"
                    ? "text-blue-500"
                    : "text-gray-500"
                }`}
                onClick={() => handleModeSelection("mobilemoney")}
              >
                <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
                <span className="font-semibold">Mobile Money</span>
              </button>
              <button
                className={`flex items-center focus:outline-none ${
                  selectedMode === "cards" ? "text-blue-500" : "text-gray-500"
                }`}
                onClick={() => handleModeSelection("cards")}
              >
                <FontAwesomeIcon icon={faCreditCard} className="mr-2" />
                <span className="font-semibold">Cards</span>
              </button>
            </div>
          </div>

          {showMobileMoneyOptions && (
            <div className="mb-4">
              <label className="block mb-2 font-semibold">Channel</label>
              <div className="flex space-x-4">
                <button
                  className={`flex items-center focus:outline-none ${
                    selectedChannel === "mpesa"
                      ? "text-blue-500"
                      : "text-gray-500"
                  }`}
                  onClick={() => handleMobileMoneyClick("mpesa")}
                >
                  <span className="font-semibold">M-Pesa</span>
                </button>
                <button
                  className={`flex items-center focus:outline-none ${
                    selectedChannel === "airtel"
                      ? "text-blue-500"
                      : "text-gray-500"
                  }`}
                  onClick={() => handleMobileMoneyClick("airtelmoney")}
                >
                  <span className="font-semibold">Airtel Money</span>
                </button>
              </div>
              <label className="block mb-2 font-semibold">Amount</label>
              <input
                type="text"
                value={withdrawalAmount}
                placeholder="Enter amount"
                onChange={(e) => setWithdrawalAmount(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
              />
              <label className="block mb-2 font-semibold">Phone Number</label>
              <input
                type="text"
                value={phoneNumber}
                placeholder="Enter phone number start with 2547 or 2541"
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
              />
            </div>
          )}

          {showCardPaymentOptions && (
            <div className="mb-4">
              <label className="block mb-2 font-semibold">Amount</label>
              <input
                type="text"
                value={withdrawalAmount}
                onChange={(e) => setWithdrawalAmount(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
              />
              <label className="block mb-2 font-semibold">Sender Names</label>
              <input
                type="text"
                value={senderNames}
                onChange={(e) => setSenderNames(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
              />
              <label className="block mb-2 font-semibold">Bank Account</label>
              <input
                type="text"
                value={bankAccount}
                onChange={(e) => setBankAccount(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2 mb-4"
              />
              <label className="block mb-2 font-semibold">Bank</label>
              <select
                value={selectedBank}
                onChange={(e) => handleBankSelect(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2"
              >
                <option value="">Select Bank</option>
                {banks.map((bank) => (
                  <option key={bank.bankcode} value={bank.bankcode}>
                    {bank.bankname}
                  </option>
                ))}
              </select>
            </div>
          )}

          <button
            onClick={handleWithdrawalSubmit}
            disabled={isLoading}
            className={`${
              isLoading ? "bg-gray-500" : "bg-blue-500"
            } text-white py-2 px-4 rounded-md focus:outline-none`}
          >
            {isLoading ? (
              <Oval
                height={20}
                width={20}
                color="white"
                visible={true}
                ariaLabel="loading-indicator"
                secondaryColor="white"
              />
            ) : (
              "Submit Withdrawal"
            )}
          </button>
        </div>
      </div>

      {isOTPOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Enter OTP</h2>
            <div className="flex justify-between mb-4">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  className="border border-gray-300 text-center rounded-md p-2 w-12 text-lg"
                />
              ))}
            </div>
            <div className="flex justify-between">
              <button
                onClick={handleVerifyOTP}
                disabled={isLoading}
                className={`${
                  isLoading
                    ? "bg-gray-500"
                    : "bg-blue-500 text-white py-2 px-4 rounded-md focus:outline-none"
                } text-white py-2 px-4 rounded-md focus:outline-none`}
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="white"
                    visible={true}
                    ariaLabel="loading-indicator"
                    secondaryColor="white"
                  />
                ) : (
                  "Verify OTP"
                )}
              </button>
              <button
                onClick={closeModal}
                className="bg-gray-500 text-white py-2 px-4 rounded-md focus:outline-none"
              >
                Cancel
              </button>
              <button
                onClick={handleResendOTP}
                disabled={isLoading}
                className={`${
                  isLoading
                    ? "bg-gray-500"
                    : "bg-green-500 text-white py-2 px-4 rounded-md focus:outline-none"
                } text-white py-2 px-4 rounded-md focus:outline-none`}
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="white"
                    visible={true}
                    ariaLabel="loading-indicator"
                    secondaryColor="white"
                  />
                ) : (
                  " Resend OTP"
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {isOTPCardOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-semibold mb-4">Enter OTP</h2>
            <div className="flex justify-between mb-4">
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  className="border border-gray-300 text-center rounded-md p-2 w-12 text-lg"
                />
              ))}
            </div>
            <div className="flex justify-between">
              <button
                onClick={handleVerifyCardOTP}
                disabled={isLoading}
                className={`${
                  isLoading
                    ? "bg-gray-500"
                    : "bg-blue-500 text-white py-2 px-4 rounded-md focus:outline-none"
                } text-white py-2 px-4 rounded-md focus:outline-none`}
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="white"
                    visible={true}
                    ariaLabel="loading-indicator"
                    secondaryColor="white"
                  />
                ) : (
                  "Verify OTP"
                )}
              </button>
              <button
                onClick={closeModal}
                className="bg-gray-500 text-white py-2 px-4 rounded-md focus:outline-none"
              >
                Cancel
              </button>
              <button
                onClick={handleResendCardOTP}
                disabled={isLoading}
                className={`${
                  isLoading
                    ? "bg-gray-500"
                    : "bg-green-500 text-white py-2 px-4 rounded-md focus:outline-none"
                } text-white py-2 px-4 rounded-md focus:outline-none`}
              >
                {isLoading ? (
                  <Oval
                    height={20}
                    width={20}
                    color="white"
                    visible={true}
                    ariaLabel="loading-indicator"
                    secondaryColor="white"
                  />
                ) : (
                  " Resend OTP"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </AuthGuard>
    </RoleGuard>
  );
};

export default WithdrawalsPage;
