import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { RoleGuard } from "../App";

const MaintenancePage = () => {
  const navigate = useNavigate();
  const [maintenanceRequests, setMaintenanceRequests] = useState([]);

  useEffect(() => {
    // Retrieve propertyId and token from local storage
    const propertyId = localStorage.getItem("selectedPropertyId");
    const token = localStorage.getItem("token");

    // Fetch maintenance requests using axios
    axios
      .get(
        `https://backend.inyumba.co.ke/iNyumba/maintenance/properties/${propertyId}/maintenance-requests`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setMaintenanceRequests(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching maintenance requests:", error);
      });
  }, []);

  const handleUpdateClick = (requestId) => {
    localStorage.setItem("selectedMaintenanceRequestId", requestId);
    navigate("/landlord/updatemaintenance");
  };

  return (
    <RoleGuard>
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Maintenance Requests</h1>
        <div>
          {maintenanceRequests.map((request) => (
            <div key={request.id} className="border rounded p-4 my-2">
              <h2 className="text-xl font-semibold">{request.title}</h2>
              <p>Description: {request.request_content}</p>
              <p>Status: {request.status}</p>
              <p>House Number: {request.house_no}</p>
              <button
                onClick={() => handleUpdateClick(request.request_id)}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Update Maintenance
              </button>
            </div>
          ))}
        </div>
      </div>
    </AuthGuard>
    </RoleGuard>
  );
};

export default MaintenancePage;
