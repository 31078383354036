import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard, RoleGuard } from "../App";

const AddPropertyKYCPage = () => {
  const [propertyId, setPropertyId] = useState("");
  const [ownerId, setOwnerId] = useState("");
  const [missingFields, setMissingFields] = useState([]); // Track missing fields
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch propertyId from local storage
    const selectedPropertyId = localStorage.getItem("selectedPropertyId");
    setPropertyId(selectedPropertyId);

    // Fetch ownerId from local storage
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    setOwnerId(userData.unique);

    // Fetch missing KYC documents
    const token = localStorage.getItem("token");
    axios
      .get(`https://backend.inyumba.co.ke/iNyumba/propertykyc/fetchavailability/${selectedPropertyId}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.data.responseCode !== "200") {
          setMissingFields(response.data.response); // Store missing documents
        }
      })
      .catch((error) => {
        console.error("Error fetching missing KYC documents:", error);
        toast.error("Failed to check missing KYC documents.");
      });
  }, []);

  const handleFileSelect = async (e, fieldName) => {
    const file = e.target.files[0];
    setLoading(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("file", file);

      const response = await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/kyc/kyc/upload",
        formDataToSend,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );

      // Update formData with uploaded file URL
      setFormData((prevData) => ({ ...prevData, [fieldName]: response.data.response.coverUri }));
    } catch (error) {
      console.error(`Error uploading ${fieldName}:`, error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (Object.values(formData).some((value) => value === "")) {
        toast.error("Not all required files uploaded");
        return;
      }

            console.log("Final submission data:", formData);

      const postData = { ...formData, propertyId, ownerId };
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/propertykyc/add/property/${propertyId}/owner/${ownerId}`,
        postData,
        {
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        }
      );

      toast.success(response.data.response);
      navigate(-1);
    } catch (error) {
      toast.error(error.response?.data?.response || "Failed to submit KYC data");
      console.error("Error submitting KYC data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <RoleGuard>
      <AuthGuard>
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-3xl font-semibold mb-4">Property KYC</h1>
          <h2 className="text-xl mb-4">Please upload the missing documents as PDFs for approval</h2>

          {missingFields.length === 0 ? (
            <p className="text-green-500 font-semibold">All required documents are already submitted!</p>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              {missingFields.includes("Your ID") && (
                <div className="flex flex-col">
                  <label htmlFor="id_number_file" className="mb-1">Landlord ID File</label>
                  <input type="file" id="id_number_file" onChange={(e) => handleFileSelect(e, "id_number")} className="w-full" />
                  {loading && <div>Loading...</div>}
                </div>
              )}

              {missingFields.includes("Your KRA PIN") && (
                <div className="flex flex-col">
                  <label htmlFor="krapin_file" className="mb-1">KRA PIN File</label>
                  <input type="file" id="krapin_file" onChange={(e) => handleFileSelect(e, "krapin")} className="w-full" />
                  {loading && <div>Loading...</div>}
                </div>
              )}

              {missingFields.includes("Utility Bills") && (
                <div className="flex flex-col">
                  <label htmlFor="utility_bills_file" className="mb-1">Utility Bills File</label>
                  <input type="file" id="utility_bills_file" onChange={(e) => handleFileSelect(e, "utility_bills")} className="w-full" />
                  {loading && <div>Loading...</div>}
                </div>
              )}

              {missingFields.includes("Tenant Agreement Template") && (
                <div className="flex flex-col">
                  <label htmlFor="tenantagreementtemplate_file" className="mb-1">Tenant Agreement Template</label>
                  <input type="file" id="tenantagreementtemplate_file" onChange={(e) => handleFileSelect(e, "tenantagreementtemplate")} className="w-full" />
                  {loading && <div>Loading...</div>}
                </div>
              )}

              {missingFields.includes("Business KRA PIN") && (
                <div className="flex flex-col">
                  <label htmlFor="business_pin_file" className="mb-1">Business PIN File</label>
                  <input type="file" id="business_pin_file" onChange={(e) => handleFileSelect(e, "business_pin")} className="w-full" />
                  {loading && <div>Loading...</div>}
                </div>
              )}

              {missingFields.length > 0 && (
      <button
        type="button"
        onClick={handleSubmit}
        disabled={loading}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
      >
        {loading ? "Submitting..." : "Submit"}
      </button>
    )}
            </form>
          )}
        </div>
      </AuthGuard>
    </RoleGuard>
  );
};

export default AddPropertyKYCPage;
