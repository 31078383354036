import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { RoleGuard } from "../App";

const ArchivedDataSettlements = () => {
  const [year, setYear] = useState("");
  const [settlements, setSettlements] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("token");
  const propertyId = localStorage.getItem("selectedPropertyId");

  const fetchSettlements = () => {
    if (!year.match(/^\d{4}$/)) {
      toast.error("Please enter a valid 4-digit year");
      return;
    }

    setLoading(true);

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/admin/settlementcheck/landlordbyyear/${propertyId}`,
        { year },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setSettlements(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching settlements:", error);
        toast.error("Error fetching settlements");
      })
      .finally(() => setLoading(false));
  };

  return (
    <RoleGuard>
      <AuthGuard>
        <div className="flex flex-col items-center min-h-screen bg-gray-100 p-6">
          <h3 className="text-xl font-semibold mb-4">Enter Year:</h3>

          <div className="flex space-x-2 mb-4">
            <input
              type="number"
              maxLength={4}
              placeholder="YYYY"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="w-32 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
            />
            <button
              onClick={fetchSettlements}
              disabled={loading}
              className="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 disabled:opacity-50"
            >
              {loading ? "Loading..." : "View Settlements"}
            </button>
          </div>

          {settlements.length > 0 ? (
            <div className="container mx-auto px-4 py-8">
              <table className="w-full border-collapse">
                <thead>
                  <tr className="bg-blue-500 text-white">
                    <th className="py-2 px-4">Settlement ID</th>
                    <th className="py-2 px-4">Amount</th>
                    <th className="py-2 px-4">Transaction Cost</th>
                    <th className="py-2 px-4">Reference</th>
                    <th className="py-2 px-4">Payment Reference</th>
                    <th className="py-2 px-4">Mode</th>
                    <th className="py-2 px-4">Status</th>
                    <th className="py-2 px-4">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {settlements.map((item, index) => (
                    <tr
                      key={item.settlement_id}
                      className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
                    >
                      <td className="py-2 px-4 text-center">
                        {item.settlement_id}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {item.total_amount_paid}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {item.transactioncost}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {item.reference}
                      </td>
                      <td className="py-2 px-4 text-center">
                        {item.paymentreference}
                      </td>
                      <td className="py-2 px-4 text-center">{item.mode}</td>
                      <td className="py-2 px-4 text-center">{item.status}</td>
                      <td className="py-2 px-4 text-center">
                        {new Date(item.created_at).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-gray-600 mt-4">No settlements found</p>
          )}
        </div>
      </AuthGuard>
    </RoleGuard>
  );
};

export default ArchivedDataSettlements;
