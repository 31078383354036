import React, { useEffect, useState } from "react";
import axios from "axios";
import { Chart } from "react-google-charts";

const TransactionC2BStatsView = () => {
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true);

  const propertyId = localStorage.getItem("selectedPropertyId");
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/landlordviewc2byearlymonthly",
          {
            headers: { Authorization: `Bearer ${token}` },
            params: { propertyid: propertyId },
          }
        );
        setResponse(data.response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [propertyId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Prepare data for Google Line Chart
  const lineChartData = [
    ["Month", "Total Amount (KSH)"],
    ...response.map((data) => [data.month, parseFloat(data.total_amount)]),
  ];

  // Prepare data for Google Pie Chart
  const pieChartData = [
    ["Month", "Amount"],
    ...response.map((data) => [data.month, parseFloat(data.total_amount)]),
  ];

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      {/* Table Container */}
      <div
        style={{
          background: "#fff",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          marginBottom: "30px",
        }}
      >
        <h2 style={{ textAlign: "center", color: "#333" }}>Income Summary</h2>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            marginTop: "20px",
          }}
        >
          <thead>
            <tr style={{ background: "#007bff", color: "#fff" }}>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                Month
              </th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                No of Payments
              </th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                Amount (KSH)
              </th>
            </tr>
          </thead>
          <tbody>
            {response.map((data, index) => (
              <tr
                key={index}
                style={{ background: index % 2 === 0 ? "#f9f9f9" : "#fff" }}
              >
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {data.month}
                </td>
                <td
                  style={{
                    padding: "10px",
                    border: "1px solid #ddd",
                    textAlign: "center",
                  }}
                >
                  {data.count}
                </td>
                <td
                  style={{
                    padding: "10px",
                    border: "1px solid #ddd",
                    textAlign: "right",
                  }}
                >
                  {data.total_amount}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Charts Container */}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          gap: "20px",
        }}
      >
        {/* Line Chart */}
        <div
          style={{
            flex: "1",
            minWidth: "400px",
            background: "#fff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <h2 style={{ textAlign: "center", color: "#333" }}>Income Trend</h2>
          <Chart
            chartType="LineChart"
            width="100%"
            height="350px"
            data={lineChartData}
            options={{
              hAxis: { title: "Month" },
              vAxis: { title: "Total Amount (KSH)" },
              legend: "none",
              colors: ["#007bff"],
              curveType: "function",
            }}
          />
        </div>

        {/* Pie Chart */}
        <div
          style={{
            flex: "1",
            minWidth: "400px",
            background: "#fff",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <h2 style={{ textAlign: "center", color: "#333" }}>
            Income Distribution
          </h2>
          <Chart
            chartType="PieChart"
            width="100%"
            height="350px"
            data={pieChartData}
            options={{
              pieHole: 0.4,
              is3D: false,
              legend: { position: "right" },
              colors: ["#007bff", "#28a745", "#ffc107", "#dc3545", "#17a2b8"],
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TransactionC2BStatsView;
