import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import axios from "axios";

const MoneyStatistics = ({ propertyId }) => {
  const [monthlyData, setMonthlyData] = useState(null);
  const [annualData, setAnnualData] = useState(null);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [monthlyResponse, annualResponse] = await Promise.all([
          axios.get(
            `https://backend.inyumba.co.ke/iNyumba/user/moneystatisticslandlord/viewmonths/${propertyId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          ),
          axios.get(
            `https://backend.inyumba.co.ke/iNyumba/user/moneystatisticslandlord/viewannual/${propertyId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          ),
        ]);

        setMonthlyData(monthlyResponse.data?.response || null);
        setAnnualData(annualResponse.data?.response || null);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [propertyId]);

  const formatChartData = (data) => {
    if (!data) return [];

    const actualAmount = data.actualamountminuscommision || 0;
    const commission = data.commission || 0;
    const settlementAmount = parseFloat(data.settlementAmount) || 0;
    const transactionCost = data.transactionCost || 0;

    const total =
      actualAmount + commission + settlementAmount + transactionCost;

    if (total === 0) return [];

    return [
      ["Category", "Amount"],
      ["Net Profit", actualAmount],
      ["Commission", commission],
      ["Withdrawals", settlementAmount],
      ["Transaction Cost", transactionCost],
    ];
  };

  const renderChart = (data) => (
    <Chart
      chartType="PieChart"
      width="100%"
      height="300px"
      data={formatChartData(data)}
      options={{
        pieHole: 0.4,
        is3D: false,
        backgroundColor: "transparent",
        chartArea: { width: "90%", height: "80%" },
        legend: { position: "bottom" },
      }}
    />
  );

  const renderTable = (data) => (
    <table
      style={{ width: "100%", borderCollapse: "collapse", marginTop: "10px" }}
    >
      <thead>
        <tr style={{ backgroundColor: "#f2f2f2" }}>
          <th style={tableHeaderStyle}>Category</th>
          <th style={tableHeaderStyle}>Amount</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          formatChartData(data)
            .slice(1)
            .map(([category, amount], index) => (
              <tr
                key={index}
                style={index % 2 === 0 ? rowEvenStyle : rowOddStyle}
              >
                <td style={tableCellStyle}>{category}</td>
                <td style={tableCellStyle}>{amount.toLocaleString()}</td>
              </tr>
            ))}
      </tbody>
    </table>
  );

  return (
    <div style={containerStyle}>
      {/* Monthly Statistics */}
      <div style={sectionStyle}>
        <h3>This Month's Statistics</h3>
        {loading ? (
          <p>Loading...</p>
        ) : monthlyData?.monthlySales === 0 ? (
          <p style={{ color: "#757575" }}>
            No transactions recorded this month.
          </p>
        ) : (
          <>
            {renderChart(monthlyData)}
            {renderTable(monthlyData)}
          </>
        )}
      </div>

      {/* Annual Statistics */}
      <div style={sectionStyle}>
        <h3>Annual Statistics</h3>
        {loading ? (
          <p>Loading...</p>
        ) : annualData ? (
          <>
            {renderChart(annualData)}
            {renderTable(annualData)}
          </>
        ) : (
          <p>No annual transactions recorded.</p>
        )}
      </div>
    </div>
  );
};

// Styles
const containerStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: "30px",
  marginTop: "20px",
};

const sectionStyle = {
  textAlign: "center",
  width: "350px",
};

const tableHeaderStyle = {
  padding: "10px",
  textAlign: "left",
  borderBottom: "2px solid #ddd",
};

const tableCellStyle = {
  padding: "8px",
  borderBottom: "1px solid #ddd",
};

const rowEvenStyle = {
  backgroundColor: "#f9f9f9",
};

const rowOddStyle = {
  backgroundColor: "#fff",
};

export default MoneyStatistics;
