import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { AuthGuard } from "../App";
import { RoleGuard } from "../App";

const RecordsPage = () => {
  const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const token = localStorage.getItem("token");
  const PropertyId = localStorage.getItem("selectedPropertyId");
  const [selectedMonth, setSelectedMonth] = useState("");
const [selectedYear, setSelectedYear] = useState("");

  useEffect(() => {
    // Fetch records
    if (token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/viewrecordsrentlandlordyear/${PropertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setRecords(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching records:", error);
        });
    }
  }, [token]);

  const handleDownload = (format) => {
  axios
    .get(
      `https://backend.inyumba.co.ke/iNyumba/reportsdownload/${PropertyId}?format=${format}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob", // Important for file downloads
      }
    )
    .then((response) => {
      const blob = new Blob([response.data], {
        type: format === "pdf" ? "application/pdf" : "text/csv",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `inyumbapaidrentrecords.${format}` // Dynamic filename
      );
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up
    })
    .catch((error) => {
      console.error(`Error downloading ${format.toUpperCase()} file:`, error);
    });
};


  const handleArchivedData = () => {
    navigate("/landlord/archiveddatarecords");
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const maskPhone = (phone) => {
    if (!phone) return ""; // Handle undefined or null phone numbers
    return phone.slice(0, 3) + "***" + phone.slice(-4); // Mask middle digits
  };



const handleDownloadByMonthAndYear = (format) => {
  if (!selectedMonth || !selectedYear) {
    alert("Please select both month and year before downloading.");
    return;
  }

  axios
    .post(
      `https://backend.inyumba.co.ke/iNyumba/reportsdownloadbymonth/${PropertyId}?format=${format}`, 
      {
        month: selectedMonth,
        year: selectedYear,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        responseType: "blob", // Required for file downloads
      }
    )
    .then((response) => {
      const blob = new Blob([response.data], {
        type: format === "pdf" ? "application/pdf" : "text/csv",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `inyumbapaidrentrecords.${format}`
      );
      document.body.appendChild(link);
      link.click();
      link.remove(); // Cleanup
    })
    .catch((error) => {
      console.error(`Error downloading ${format.toUpperCase()} file:`, error);
    });
};


  return (
    <RoleGuard>
    <AuthGuard>
      <div className="flex flex-col items-center">
        {/* Go back button */}
        <button
          onClick={() => navigate(-1)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back
        </button>


        <button
            onClick={handleArchivedData}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-md m-2"
          >
            View Archived Data
          </button>

        {/* Records List */}
        <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-semibold mb-4">Records View</h1>
        <h1 className="text-3xl font-semibold mb-4">Download All Records</h1>
        <div className="flex items-center gap-4 mb-4">
          <button
                onClick={() => handleDownload("csv")}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-md flex items-center"
              >
                Download CSV
              </button>
              <button
                onClick={() => handleDownload("pdf")}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-6 rounded-md flex items-center"
              >
                Download PDF
              </button>
          </div>

          <h1 className="text-3xl font-semibold mb-4">Download Records by Month</h1>

          <div className="flex items-center gap-4 mb-4">
  {/* Month Dropdown */}
  <select
    value={selectedMonth}
    onChange={(e) => setSelectedMonth(e.target.value)}
    className="border p-2 rounded-md"
  >
    <option value="">Select Month</option>
    {[
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ].map((month, index) => (
      <option key={index} value={index + 1}>
        {month}
      </option>
    ))}
  </select>

  {/* Year Dropdown */}
  <select
    value={selectedYear}
    onChange={(e) => setSelectedYear(e.target.value)}
    className="border p-2 rounded-md"
  >
    <option value="">Select Year</option>
    {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map(
      (year) => (
        <option key={year} value={year}>
          {year}
        </option>
      )
    )}
  </select>
</div>

<div className="flex gap-4">
  <button
    onClick={() => handleDownloadByMonthAndYear("csv")}
    className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-6 rounded-md"
  >
    Download CSV by Month & Year
  </button>

  <button
    onClick={() => handleDownloadByMonthAndYear("pdf")}
    className="bg-red-500 hover:bg-red-700 text-white font-bold py-3 px-6 rounded-md"
  >
    Download PDF by Month & Year
  </button>
</div>

          {records.length > 0 ? (
            <table className="table-auto w-full mb-4">
              <thead>
                <tr>
                  <th className="px-4 py-2">Record ID</th>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Phone</th>
                  <th className="px-4 py-2">House Number</th>
                  <th className="px-4 py-2">Water Bill</th>
                  <th className="px-4 py-2">Trash Bill</th>
                  <th className="px-4 py-2">Rent Bill</th>
                  <th className="px-4 py-2">Created At</th>
                </tr>
              </thead>
              <tbody>
                {records.map((record) => (
                  <tr key={record.record_id}>
                    <td className="border px-4 py-2">{record.record_id}</td>
                    <td className="border px-4 py-2">{record.user_name}</td>
                    <td className="border px-4 py-2">
                      {maskPhone(record.phone)}
                    </td>
                    <td className="border px-4 py-2">{record.house_number}</td>
                    <td className="border px-4 py-2">{record.water_bill}</td>
                    <td className="border px-4 py-2">{record.trash_bill}</td>
                    <td className="border px-4 py-2">{record.rent_bill}</td>
                    <td className="border px-4 py-2">{record.created_at}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No records found.</p>
          )}
        </div>
      </div>
    </AuthGuard>
    </RoleGuard>
  );
};

export default RecordsPage;
