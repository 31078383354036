import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Oval } from "react-loader-spinner"; // Import loader
import { RoleGuard } from "../App";

const PendingPayments = () => {
  const [pendingData, setPendingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPendingData = async () => {
      setLoading(true);

      const token = localStorage.getItem("token");
      const userDataString = localStorage.getItem("user");
      const userData = JSON.parse(userDataString);
      const userId = userData.unique;
      const propertyId = localStorage.getItem("selectedPropertyId");

      try {
        const response = await axios.get(
      `https://backend.inyumba.co.ke/iNyumba/admin/bill/viewabillandbalances/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setPendingData(response.data.response); // Adjust based on your response structure
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchPendingData();
  }, []);

  // Render loading state while fetching data
  if (loading) {
    return <div className="container mx-auto px-4 py-8">Loading...</div>;
  }

  return (
    <RoleGuard>
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Pending Payments</h1>

        <div className="bg-white shadow-md rounded-md p-4">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-200">
                <th className="py-2 px-4 border-b">Tenant Name</th>
                <th className="py-2 px-4 border-b">phone</th>
                <th className="py-2 px-4 border-b">House Number</th>
                <th className="py-2 px-4 border-b">Water Bill</th>
                <th className="py-2 px-4 border-b">Rent Bill</th>
                <th className="py-2 px-4 border-b">Trash Bill</th>
                <th className="py-2 px-4 border-b">Billable Amount</th>
                <th className="py-2 px-4 border-b">Amount Paid</th>
                <th className="py-2 px-4 border-b">Balance</th>                
              </tr>
            </thead>
            <tbody>
              {pendingData.length > 0 ? (
                pendingData.map((pending, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">
                      {pending.user_name}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {pending.phone}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {pending.house_no}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {pending.water_bill}
                    </td>
                    <td className="py-2 px-4 border-b">
                      {pending.rent_bill}
                    </td>
                    <td className="py-2 px-4 border-b">{pending.trashbill}</td>
                    <td className="py-2 px-4 border-b">{pending.total_amount}</td>
                    <td className="py-2 px-4 border-b">{pending.amount_paid}</td>
                    <td className="py-2 px-4 border-b">{pending.balance}</td>
                   
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="py-2 px-4 text-center">
                    No Pending Payments found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AuthGuard>
    </RoleGuard>
  );
};

export default PendingPayments;
